<template>
  <div>
    <h2 class="hidden">Discover history, one day at a time</h2>
    <div class="mt-8 flex flex-col md:flex-row gap-4">
      <a href="https://testflight.apple.com/join/w5fTlNIy">
        <img src="~/assets/App-Store.png" alt="App Store" class="h-12" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.almanacc.app">
        <img src="~/assets/Google-Play.png" alt="Google Play" class="h-12" />
      </a>
    </div>
    <h3 class="absolute bottom-6 left-6 text-white">
      Almana.cc Beta a généré <UBadge><NumberFlow :value="imageCount" /></UBadge> images
    </h3>
  </div>
</template>

<script setup>
import NumberFlow from '@number-flow/vue'

definePageMeta({
  layout: 'splash',
})

const { data: imageCount, refresh } = await useFetch('/api/ai/imageCount')

onMounted(async () => {
  setInterval(async () => {
    await refresh()
  }, 1000)
})
</script>
